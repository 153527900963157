import { Card, Grid, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
// import MaskGroup from 'Assets/aboutUs/MaskGroup.png';
import LibraryBanner from 'Assets/Home/carosoel/InnerPageBanners/LibraryBanner.png';
import Image2 from 'Assets/library/dont-give-up-quotes.png';
import { useTranslation } from 'react-i18next';
import MessageCard from './Card/MessageCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchIcon from '@mui/icons-material/Search';
import library1 from 'Assets/library1.svg';
import { messageList } from './Constants';



const useStyles = makeStyles((theme) => ({
    molemLiberary: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)',
    },
    h1Label: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "72px",
        color: "#ffffff",
    },
    h1Text: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "16px"
    },
    headingContainer: {
        minHeight: "300px",
        backgroundImage: `url(${LibraryBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "bottom",
        marginBottom: "30px"
    },
    iconColor: {
        // color: "#45A041",
        margin: "0 5px",
        fontSize: "28px"
    },
    messages: {
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "26px",
        color: "#45A041",
        marginBottom: "20px"
    },
    messageHeading: {
        fontSize: "48px",
        fontWeight: "normal",
        textTransform: "none",
        textAlign: "justify",
    },
    exploreMore: {
        textTransform: "none",
        fontSize: "20px",
        lineHeight: "26px",
        backgroundColor: "transparent",
        color: "#45A041",
        '&:hover': {
            backgroundColor: "transparent",
            textDecoration: "underline"
        },
    }
}))

const MessagePage = () => {
    const classes = useStyles()
    const { t } = useTranslation();
    const [currency, setCurrency] = useState();
    const [searchMessage, setSearchMessage] = useState();

    const handleChange = (event) => {
        setCurrency(event.target.value);
    };


    const categoryArray = [
        {
            title: t("library.all"),
            value: "0"
        },
        {
            title: t("library.SavingsFinancialPlanning"),
            value: "1"
        },
        {
            title: t("library.investment"),
            value: "2"
        },
        {
            title: t("library.HowProtectYourself"),
            value: "3"
        },
    ]

    return (
        <div className={classes.molemLiberary}>
            <Grid container justifyContent="center" alignItems="center" className={classes.headingContainer}>
                <Grid item>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} style={{ maxWidth: "500px" }}>
                            <h1 className={classes.h1Label}>{t("library.messages")}</h1>
                        </Grid>
                        <Grid item xs={12} style={{ maxWidth: "550px", padding: "0 20px" }}>
                            <Card style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                <Grid container>
                                    <Grid item xs={12} md={8} lg={9}>
                                        <TextField
                                            placeholder="بحث"
                                            fullWidth
                                            value={searchMessage}
                                            onChange={(e) => setSearchMessage(e?.target?.value)}
                                            size='small'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3}>
                                        <TextField
                                            select
                                            value={currency}
                                            onChange={handleChange}
                                            size='small'
                                            fullWidth
                                            placeholder="الحملات"
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            {categoryArray?.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.title}
                                                </option>
                                            ))}
                                        </TextField>

                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <InfiniteScroll
                dataLength={messageList?.length}
                // next={this.fetchMoreData}
                // hasMore={this.state.hasMore}
                loader={<h4>Loading...</h4>}
                // height={400}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                }
            >
                <Grid container style={{ padding: "0 2% 50px" }}>
                    {messageList?.filter((item) => item.type === "message")?.map((item) => (
                        <Grid item xs={12} md={4} lg={4} xl={3}>
                            <MessageCard item={item} />
                        </Grid>
                    ))}
                </Grid>
            </InfiniteScroll>
        </div>
    );
};

export default MessagePage;
