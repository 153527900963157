
import React from 'react';
import Logo from 'Assets/mlmlogo.svg';
import BtnBase from 'Components/Buttons/BtnBase';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { AppBar, Button, Drawer, Grid, Hidden, Toolbar } from '@mui/material';
import { LocalStorage } from 'Utils/LocalStorage';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';

import twiterIcon from 'Assets/commingSoon/twitterIcon.svg';
import instagramIcon from 'Assets/commingSoon/instagramIcon.svg';
import youtubeIcon from 'Assets/commingSoon/youtubeIcon.svg';

const color1 = '#45A041'
const storage = new LocalStorage();

const useStyle = makeStyles((theme) => ({
    localeBtn: {
        backgroundColor: theme.palette.primary.light,
    },
    appBar: {
        backgroundColor: 'white !important',
        [theme.breakpoints.up("md")]: {
            padding: '10px 50px'
        }
    },
    navLinkActive: {
        color: 'rgba(0,0,0,1)',
        margin: '15px',
        cursor: "default"
    },
    navLinkInactive: {
        color: 'rgba(0,0,0,0.4)',
        margin: '15px',
        cursor: "pointer",
        '&:hover': {
            color: 'rgba(0,0,0,0.7)',
        }
    },
    menuButton: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "inline",
            float: "right",
        },
    },
    drawer: {
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    },
    desktop: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    socialLinks:{
        [theme.breakpoints.down("lg")]: {
            display: "none",
        },
    },
    mobile: {
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    },
    headerPaddingWrap: {
        padding: "0px",
        [theme.breakpoints.up("lg")]: {
          padding: '0 60px',
        },
        [theme.breakpoints.up("xl")]: {
            padding: "0px",
        },
    }
}));

export default function Header() {
    const styles = useStyle();
    const { i18n, t } = useTranslation();
    const history = useHistory()
    const [state, setState] = React.useState({
        top: false,
    });

    const NavLinks = [
        {
            text: t('navLinks.aboutUs'),
            path: '/about',
        },
        // {
        //     text: t('navLinks.library'),
        //     path: '/molem-library',
        // },
        {
            text: t('navLinks.khalijiWeek'),
            path: '/khaliji-investor-week',
        },
        {
            text: t('navLinks.khalijiAwards'),
            path: '/smart-khaliji-investor-award',
        },
        {
            text: t('navLinks.contactUs'),
            path: '/contact',
        },
    ];

    const linksArray = [
        {
            icon: youtubeIcon,
            path: "https://www.youtube.com/channel/UChrdz7OmRR7OaOZ3Dl_WkWA"
        },
        {
            icon: instagramIcon,
            path: "https://instagram.com/mulimgcc"
        },
        {
            icon: twiterIcon,
            path: "https://twitter.com/mulimgcc"
        },
    ]

    const changeLanguage = () => {
        storage.set('language', i18n.language === 'ar' ? 'en' : 'ar');
        i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
        window.location.reload()
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            style={{ color: "#000", textAlign: "center", paddingBottom: "10px" }}
        >
            <ul>
                {NavLinks.map((l, index, key) => (
                    <NavLink onClick={() => setState({ top: false })} to={l.path} className={NavLinks[index].path === `/${window.location.pathname.split('/')[1]}` ? styles.navLinkActive : styles.navLinkInactive} style={{ margin: '6px' }}>
                        <li>{l.text}</li>
                    </NavLink>
                ))}
                {/* <li>
                    <Button onClick={changeLanguage} variant="outlined">
                        <LanguageIcon />&nbsp;{t('common.lang')}
                    </Button>
                </li> */}
            </ul>
        </div>
    );


    return (
        <>
            <div style={{ position: "sticky", top: "0", zIndex: "10" }}>
                <AppBar elevation={0} position="relative" className={`${styles.appBar}`}>
                    <Toolbar className="justify-between">
                        <Grid maxWidth="xl" className={styles.headerPaddingWrap}  container justifyContent="space-between" alignItems="center" style={{ background: "rgba(255,255,255,0.8)", margin: "0 auto" }}>
                            <Grid item>
                                <NavLink className={styles.navLinkInactive} to="/">
                                    <img src={Logo} className="w-auto max-h-24" alt="Aseel Logo" />
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" className={styles.desktop}>
                                    {NavLinks.map((l, index, key) => (
                                        <Grid item>
                                            <a onClick={() => history.push(l?.path)} className={NavLinks[index].path === `/${window.location.pathname.split('/')[1]}` ? styles.navLinkActive : styles.navLinkInactive} to={l.path}>
                                                {l.text}
                                            </a>
                                        </Grid>
                                    ))}
                                    {/* <Grid item >
                                        <Button onClick={changeLanguage} variant="outlined">
                                            <LanguageIcon />&nbsp;{t('common.lang')}
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" spacing={4} className={styles.socialLinks}>
                                    {linksArray?.map((item) => {
                                        return (
                                            <Grid item>
                                                <a target="blank" href={item.path}>
                                                    <img src={item.icon} alt='' style={{ width: "30px", cursor: "pointer" }} />
                                                </a>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={styles.mobile}>
                            <Button
                                style={{ color: "#000", backgroundColor: 'primary' }}
                                onClick={toggleDrawer("top", true)}
                            >
                                <MenuIcon onClick={toggleDrawer("top", true)}
                                    onKeyDown={toggleDrawer("top", false)}
                                />
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>

            {["top"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        className={styles.drawer}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </>
    );
}
