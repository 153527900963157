import { Grid, Button, Card, Avatar } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MaskGroup1 from "Assets/Home/MaskGroup1.png";
import MaskGroup2 from "Assets/Home/MaskGroup2.png";
import about from "Assets/Home/about.png";
import arrowRight from "Assets/arrowRight.svg";
import arrowLeft from "Assets/arrowLeft.svg";
import latestTweets from "Assets/latestTweets.png";
import twiterImageSingle from "Assets/Home/TwiterImageSingle.svg";
import twitterBlue from "Assets/twitterBlue.svg";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import Carousel, { consts } from "react-elastic-carousel";
import tweetCard from "Assets/Home/tweetCard.png";
import tweetCard1 from "Assets/Home/tweetCard1.svg";
import HelmetWrapper from 'Components/HelmetWrapper/HelmetWrapper';

import HomeBanner from "Assets/Home/carosoel/MainBanner.png";
import AboutBanner from "Assets/Home/carosoel/AboutBanner.png";
import LibraryBanner from "Assets/Home/carosoel/LibraryBanner.png";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { color1, color2 } from "Pages/Constant";

import quoteBlue from "Assets/quoteBlue.svg";
import MyCarousel from "./Components/StackeCarousel";
import { useState, useEffect } from "react";
import { http, httpTwitter } from "Utils/Http/Http";
import Logo from 'Assets/logoSquare.svg';
import { useQuery } from "react-query";
import { HOMEDETAIL } from "Constants/QueriesKeys";


const useStyles = makeStyles((theme) => ({
    bannerText: {
        fontSize: "85px",
        lineHeight: "107px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: 'white',
        width: '450px',
        [theme.breakpoints.down("md")]: {
            fontSize: "50px",
            lineHeight: "65px",
            padding: '0px 100px 0 0',
            width: '100%',
            textAlign: 'center',
            maxWidth: '40vw',
            marginBottom: '0',
            textAlign: 'right',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
            lineHeight: "32px",
            padding: '0px 50px 0 0',
        },
    },
    about: {
        fontSize: '17px',
        lineHeight: '30px',
        marginBottom: '30px',
        [theme.breakpoints.down("sm")]: {
            marginBottom: '20px',
        },
    },
    section__headline: {
        color: color1,
        textAlign: "center",
        fontSize: "60px",
        fontWeight: "bold",
        lineHeight: "26px",
        textAlign: 'center',
        marginBottom: '100px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "40px",
            marginBottom: '60px',
        },
    },
    section2: {
        padding: '160px 5%',
        textAlign: 'justify',
        // background: `url(${MaskGroup1})`,
        minHeight: 'calc(100vh - 118px)',
        // minHeight: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom center",
        position: "relative",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            minHeight: 'auto',
            padding: '100px 5%',
        }
    },
    about__bg: {
        background: `url(${about})`,
        backgroundSize: "auto 80%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        position: "absolute",
        left: "0",
        top: "0",
        bottom: "0",
        width: "200px",
        transform: "rotateY(-180deg)",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    section3: {
        padding: '160px 5%',
        textAlign: 'justify',
        minHeight: 'calc(100vh - 118px)',
        // minHeight: '100vh',
        background: `url(${MaskGroup2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom center",
        background: "#F8F8F8",
        [theme.breakpoints.down("md")]: {
            padding: '100px 5%',
            minHeight: 'auto',
        }
    },
    image1: {
        display: 'block',
        margin: '0 auto'
    },
    image2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px"
    },
    exploreMore: {
        margin: '20px',
        textDecoration: 'none',
        fontSize: "20px",
        lineHeight: "26px",
        backgroundColor: "transparent",
        color: color1,
        '&:hover': {
            backgroundColor: "transparent",
        },
    },
    iconColor: {
        color: "black",
        margin: "0 10px",
        height: "28px",
    },
    iconColor1: {
        color: "black",
        margin: "0",
        height: "28px",
    },
    messages: {
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "26px",
        color: color1,
        margin: '20px'
    },
    messageHeading: {
        fontSize: "48px",
        fontWeight: "bold",
        margin: '20px'
    },
    bnnrH1: {
        color: '#fff',
        fontSize: '70px',
    },
    tweetCard: {
        padding: "82px 40px 60px 40px",
        boxShadow: "0px 4px 40px rgba(43, 89, 255, 0.08)",
        borderRadius: "10px",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            padding: "60px 16px 40px 16px",
        }
    },
    tweetCard212: {
        // background: `url(${tweetCard})`,
        background: `url(${quoteBlue})`,
        backgroundSize: "100px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
    },
    carousel: {
        position: "relative",
        "& .rec-slider-container": {
            margin: "0 auto",
        },
        "& .rec-item-wrapper": {
            height: "100%",
        }
    },
    bannerButton: {
        background: "linear-gradient(90deg, rgba(0, 0, 0, 0) 2.75%, #000000 96.22%)",
        opacity: "0.5",
        transform: "rotate(-180deg)",
        width: "180px",
        height: "100%",
        borderRadius: "0px",
        position: "absolute",
        top: "0",
        bottom: "0",
        zIndex: "1",
        '&:hover': {
            opacity: ".7",
        },
        '&:first-child': {
            background: "linear-gradient(90deg, #000000 2.75%, rgba(0, 0, 0, 0) 96.22%)",
            right: '0',
        },
        '&:nth-child(3)': {
            left: "0"
        },
        [theme.breakpoints.down("sm")]: {
            width: "80px",
        },
        "&.Mui-disabled": {
            display: "none",
        }
    },
    bannerButton__iconWrap: {
        width: "50px",
        height: "50px",
        borderRadius: "50px",
        background: "rgba(237, 237, 237, 0.3)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "rotate(-180deg)",
        [theme.breakpoints.down("sm")]: {
            width: "30px",
            height: "30px",
            borderRadius: "30px",
        },
    },
    carosoel_main: {
        width: '100%',
        minHeight: 'calc(100vh - 118px)',
        backgroundSize: "cover !important",
        backgroundRepeat: "no-repeat !important",
        backgroundPosition: "center !important",
        position: "relative",
        [theme.breakpoints.down("md")]: {
            minHeight: '50vmin',
        },
    },
    avatar: {
        margin: "0 16px",
        height: "60px",
        width: "60px",
        borderRadius: "50%",
        backgroundSize: "cover",
        [theme.breakpoints.down("sm")]: {
            height: "50px",
            width: "50px",
        },
        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "50%",
        }
    },
    avatar__twitter: {
        position: "absolute",
        top: "0",
        left: "14px",
        [theme.breakpoints.down("sm")]: {
            left: "10px",
        }
    },
    avatar__deatils: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
    }
}));

const Home = () => {
    const { t, i18n } = useTranslation();
    let { path } = useRouteMatch();
    const history = useHistory();
    const classes = useStyles();

    const breakPoints1 = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1 },
        { width: 768, itemsToShow: 1 },
        { width: 1500, itemsToShow: 1 }
    ];
const [bannerList, setBannerList] = useState()
    // const bannerList = [
    //     {
    //         heading: "برنامج التوعية الاستثمارية الخليجي",
    //         headerAlign: "flex-start",
    //         backgroundImage: HomeBanner
    //     },
    //     {
    //         heading: "عن مُلم",
    //         headerAlign: "flex-start",
    //         link: '/about',
    //         backgroundImage: AboutBanner
    //     },
    //     // {
    //     //     heading: t("navLinks.library"),
    //     //     headerAlign: "flex-start",
    //     //     link: '/molem-library',
    //     //     backgroundImage: LibraryBanner
    //     // },
    // ];
    const [tweets, setTweets] = useState([]);
    const [tweetsProfile, setTweetsProfile] = useState({
        name: "",
        username: "",
        id: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            const result = await httpTwitter.get(`/api/v1/twitter/list`);
            setTweets(result?.data?.data?.tweets?.data);
            setTweetsProfile({ ...tweetsProfile, ...result?.data?.data?.profileInfo });
        };
        fetchData();
    }, []);

    const [homePageDetails, setHomePageDetails] = useState()

    const homePageQuery = useQuery([HOMEDETAIL], () =>
        http.get(`/api/v1/pages`, {}).then(({ data }) => {
            return (
                data,
                setHomePageDetails(data?.data?.homePage),
                setBannerList(data?.data?.homePage?.carouselDetails)
            )
        }),
    );


    function myArrow({ type, onClick, isEdge }) {
        const pointer = type === consts.PREV ?
            i18n.language === 'ar' ? <ArrowForwardIosIcon className={classes.iconColor1} /> : <ArrowBackIosNewIcon className={classes.iconColor1} />
            :
            i18n.language === 'ar' ? <ArrowBackIosNewIcon className={classes.iconColor1} /> : <ArrowForwardIosIcon className={classes.iconColor1} />
        return (
            <Button onClick={onClick} className={classes.bannerButton} disabled={isEdge} style={{ minWidth: "0px", padding: '0' }}>
                <span className={classes.bannerButton__iconWrap}>{pointer}</span>
            </Button>
        )
    }

    return (
        <>
            <Grid container direction="row" justifyContent="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Carousel className={classes.carousel} isRTL={i18n.language === 'ar' ? true : false} disableArrowsOnEnd breakPoints={breakPoints1} pagination={false} enableAutoPlay={true} autoPlaySpeed={5000} enableSwipe={true} showArrows={true} style={{ margin: "0 0" }} renderArrow={myArrow}>
                        {bannerList?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={classes.carosoel_main}
                                    style={{
                                        background: `url(${item.backgroundImage})`,
                                    }}
                                >
                                    <Grid
                                        container
                                        style={{ height: '100%' }}
                                        justifyContent={item?.headerAlign}
                                        alignItems='center'
                                    >
                                        <Grid
                                            item
                                            style={{
                                                textAlign: 'center',
                                                padding: '5%',
                                                color: '#fff',
                                            }}
                                        >
                                            {item?.link ? (
                                                <h1
                                                    className={classes.bannerText}
                                                    onClick={() => history.push(item?.link)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: item?.headingColor,
                                                    }}
                                                >
                                                    {item?.heading}
                                                </h1>
                                            ) : (
                                                <h1
                                                    className={classes.bannerText}
                                                    style={{
                                                        color: item?.headingColor,
                                                    }}
                                                >
                                                    {item?.heading}
                                                </h1>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })}
                    </Carousel>
                </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="center" className={classes.section2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.about__bg}></Grid>
                <Grid sx={{ margin: "0 auto" }} item xs={12} sm={12} md={6} lg={6} xl={6} >

                    <Grid container>
                        <Grid item xs={12}>
                            <h2 className={classes.section__headline}>{t("home.text")}</h2>
                            <br />
                            <div className={classes.about}>
                                <div className='editor' dangerouslySetInnerHTML={{ __html: homePageDetails?.aboutMulim }}></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "12px" }}>
                            <Button style={{ color: color2, fontSize: "20px", fontWeight: "bold", padding: "0" }} onClick={() => history.push('/about')} >{t("home.readMore")} {i18n.language === 'ar' ? <img src={arrowLeft} className={classes.iconColor} /> : <img src={arrowRight} className={classes.iconColor} />}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" className={classes.section3}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <h2 className={classes.section__headline}>{t("home.latestTweets")}</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={10} style={{ heigt: "400px" }}>
                            <MyCarousel>
                                {
                                    tweets?.length > 0 && tweets.map((item) => {
                                        return (
                                            <Card key={item.id} className={classes.tweetCard} onClick={() => window.open(`https://twitter.com/${tweetsProfile.username}/status/${item.id}`)} >
                                                <Grid container justifyContent="center" alignItems="center" className={classes.tweetCard212}>
                                                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                                        <Grid container justifyContent="center">
                                                            <Grid item style={{ maxWidth: "400px" }}>
                                                                <p style={{ textAlign: "center" }}>{item?.text}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container justifyContent="center" alignItems="center" >
                                                            <Grid style={{
                                                                position: "relative",
                                                            }} item >
                                                                <Avatar src={Logo} alt="Tweets" className={classes.avatar} />
                                                                <span className={classes.avatar__twitter}><img src={twitterBlue} alt="Twitter Icon" /></span>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container justifyContent="center" className={classes.avatar__deatils}>
                                                                    <Grid item xs={12}>
                                                                        <span>{`@${tweetsProfile.username}`}</span>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <span>{tweetsProfile.name}</span>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        )
                                    })}
                            </MyCarousel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default Home;