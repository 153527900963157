import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import FormSuccessBackground from 'Assets/awards/FormSuccessBackground.svg';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    maxWidth: "1000px",
    maxHeight: "95vh",
    // overflow:"scroll",
    bgcolor: 'background.paper',
    borderRadius:"30px",
    boxShadow: 24,
    p: 4,
    backgroundImage: `url(${FormSuccessBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "bottom center",
};

const useStyle = makeStyles((theme) => ({

}));




const FormSubmitModal = ({ open, handleClose }) => {
    const classes = useStyle();
    const { t } = useTranslation();


    const handleCloseModal = () => {
        handleClose()
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <IconButton onClick={handleCloseModal} style={{backgroundColor:"white"}}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container style={{minHeight:"50vh"}} justifyContent="center" alignItems="center">
                        <Grid item >
                            <h3 style={{maxWidth:"300px", textAlign:"center"}}>{t("awardForm.successMessage1")}</h3>
                            <h3 style={{maxWidth:"300px", textAlign:"center"}}>{t("awardForm.successMessage2")}</h3>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default FormSubmitModal
