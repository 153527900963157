import React from 'react';
import "App.css"
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Home from 'Pages/Home/Home.js';
import BasicLayout from 'Layouts/BasicLayout/Index';
import ContactUs from 'Pages/ContactUs/ContactUs';
import AboutUs from 'Pages/AboutUs/AboutUs';
import MolemLibrary from 'Pages/MolemLibrary/MolemLibrary';
import MessagePage from 'Pages/MolemLibrary/Components/MessagePage';
import VideoPage from 'Pages/MolemLibrary/Components/VideoPage';
import LibraryAllPage from 'Pages/MolemLibrary/Components/LibraryAllPage';
import InvestorWeek from 'Pages/InvestorWeek/InvestorWeek';
import AwardPage from 'Pages/Award/AwardPage';
import Policy from 'Pages/Award/Components/Policy';
import VideographyPage from 'Pages/Award/Components/Pages/VideographyPage';
import PhotographyPage from 'Pages/Award/Components/Pages/PhotographyPage';
import DrawingPage from 'Pages/Award/Components/Pages/DrawingPage';
import FAQsPage from 'Pages/Award/Components/Pages/FAQsPage';
import 'animate.css';
import HelmetWrapper from 'Components/HelmetWrapper/HelmetWrapper';
import useHelmet from 'Hooks/useHelmet';



export default function App() {
    let { path } = useRouteMatch();
    const history = useHistory();
    const pathName = history?.location?.pathname;
    const { helmetState } = useHelmet(pathName);

    return (<BasicLayout>
                <HelmetWrapper {...helmetState} />
                <Switch>
                    <Route exact path={`${path}`} component={Home} />
                    <Route exact path={`${path}contact`} component={ContactUs} />
                    <Route exact path={`${path}about`} component={AboutUs} />

                    {/* <Route exact path={`${path}molem-library`} component={MolemLibrary} />
                    <Route exact path={`${path}molem-library/message`} component={MessagePage} />
                    <Route exact path={`${path}molem-library/all`} component={LibraryAllPage} />
                    <Route exact path={`${path}molem-library/video`} component={VideoPage} /> */}

                    <Route exact path={`${path}smart-khaliji-investor-award`} component={AwardPage} />
                    <Route exact path={`${path}smart-khaliji-investor-award/videography`} component={VideographyPage} />
                    <Route exact path={`${path}smart-khaliji-investor-award/photography`} component={PhotographyPage} />
                    <Route exact path={`${path}smart-khaliji-investor-award/drawing`} component={DrawingPage} />
                    <Route exact path={`${path}khaliji-investor-week`} component={InvestorWeek} />
                    <Route exact path={`${path}policy`} component={Policy} />
                    <Route exact path={`${path}FAQs`} component={FAQsPage} />
                </Switch>
            </BasicLayout>);
}
