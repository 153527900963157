import { Card, Grid, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
// import MaskGroup from 'Assets/common/MaskGroup.png';
import LibraryBanner from 'Assets/Home/carosoel/InnerPageBanners/LibraryBanner.png';
import { useTranslation } from 'react-i18next';
import MessageCard from './Card/MessageCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchIcon from '@mui/icons-material/Search';
import VideoCard from './Card/VideoCard';
import { messageList } from './Constants';



const useStyles = makeStyles((theme) => ({
    molemLiberary: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)',
    },
    h1Label: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "72px",
        color: "#ffffff",
    },
    h1Text: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "16px"
    },
    headingContainer: {
        minHeight: "300px",
        backgroundImage: `url(${LibraryBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "bottom",
        marginBottom: "30px"
    },
    iconColor: {
        // color: "#45A041",
        margin: "0 5px",
        fontSize: "28px"
    },
    messages: {
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "26px",
        color: "#45A041",
        marginBottom: "20px"
    },
    messageHeading: {
        fontSize: "48px",
        fontWeight: "normal",
        textTransform: "none",
        textAlign: "justify",
    },
    exploreMore: {
        textTransform: "none",
        fontSize: "20px",
        lineHeight: "26px",
        backgroundColor: "transparent",
        color: "#45A041",
        '&:hover': {
            backgroundColor: "transparent",
            textDecoration: "underline"
        },
    }
}))

const LibraryAllPage = () => {
    const classes = useStyles()
    const { t } = useTranslation();
    const [searchMessage, setSearchMessage] = useState();

    return (
        <div className={classes.molemLiberary}>
            <Grid container justifyContent="center" alignItems="center" className={classes.headingContainer}>
                <Grid item xs={12} style={{ maxWidth: "680px" }}>
                    <Grid container justifyContent="center" alignItems="center" >
                        <Grid item xs={12} >
                            <h1 className={classes.h1Label}>{t("library.all")}</h1>
                        </Grid>
                        <Grid item xs={12} >
                            <Card style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            placeholder={t("common.search")}
                                            fullWidth
                                            value={searchMessage}
                                            onChange={(e) => setSearchMessage(e?.target?.value)}
                                            size='small'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon style={{ color: "green" }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <InfiniteScroll
                dataLength={messageList.length}
                // next={this.fetchMoreData}
                // hasMore={this.state.hasMore}
                loader={<h4>Loading...</h4>}
                // height={400}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                }
            >
                <Grid container style={{ padding: "0 2%" }} spacing={4}>
                    {messageList?.map((item) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                {item.type === "video" ?
                                    <VideoCard item={item} height="280px"/>
                                    :
                                    <MessageCard item={item} height="280px"/>
                                }
                            </Grid>
                        )
                    })}
                </Grid>
            </InfiniteScroll>
        </div>
    );
};

export default LibraryAllPage;
