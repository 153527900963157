import { Button, Card, Grid, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';
import MessageCard from './Components/Card/MessageCard';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import VideoCard from './Components/Card/VideoCard';
import { useHistory, useRouteMatch } from 'react-router-dom';
import HelmetWrapper from 'Components/HelmetWrapper/HelmetWrapper';

import icon1 from 'Assets/library/icon1.svg';
import icon2 from 'Assets/library/icon2.svg';
import icon3 from 'Assets/library/icon3.svg';
import icon4 from 'Assets/library/icon4.svg';

import SearchIcon from '@mui/icons-material/Search';
import TitleBox from 'Components/TitleBox/TitleBox';
import { color2 } from 'Pages/Constant';
import { messageList } from './Components/Constants';

import LibraryBanner from "Assets/Home/carosoel/InnerPageBanners/LibraryBanner.png";

const useStyles = makeStyles((theme) => ({
  molemLiberary: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)',
  },
  iconColor: {
    // color: "black",
    margin: '0 5px',
    fontSize: '28px',
  },
  messages: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '26px',
    color: 'black',
    marginBottom: '20px',
  },
  messageHeading: {
    fontSize: '48px',
    fontWeight: 'bold',
    textTransform: 'none',
    textAlign: 'justify',
  },
  exploreMore: {
    textTransform: 'none',
    fontSize: '20px',
    lineHeight: '26px',
    backgroundColor: 'transparent',
    color: color2,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  categoryCardInActive: {
    cursor: 'pointer',
    height: '205px',
    padding: '20px .5rem',
    borderRadius: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 7px 29px 0px',
    filter: "grayscale(100%)",
    '&:hover': {
      boxShadow:
        'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
        filter: "grayscale(0%)",
    },
  },
  categoryCardActive: {
    height: '205px',
    padding: '20px',
    borderRadius: '20px',

    // border:"3px solid green",
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 7px 29px 0px, rgb(69, 160, 65) 0px 0px 0px 3px',
  },
}));

const MolemLibrary = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  let { path } = useRouteMatch();
  const history = useHistory();
  const [searchMessage, setSearchMessage] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedPlayId, setSelectedPlayId] = useState();

  const onPlayFunction = (index) => {
    setSelectedPlayId(index);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1500, itemsToShow: 4 },
  ];
  const categoryArray = [
    {
      title: t('library.SavingsFinancialPlanning'),
      icon: icon1,
    },
    {
      title: t('library.investment'),
      icon: icon3,
    },
    {
      title: t('library.HowProtectYourself'),
      icon: icon4,
    },
    {
      title: t('library.all'),
      icon: icon2,
      path: '/molem-library/all',
    },
  ];
  const headerData = {
    h1Label: t('library.molemLibrary'),
    h1Text: t('library.molemText1'),
  };

  return (
    <div className={classes.molemLiberary}>

      <TitleBox data={headerData} imageURL={LibraryBanner} h1TextColor="#ffffff" h1LabelColor='#ffffff' align="right" />
      <Grid container style={{ padding: '100px 5% 50px', marginBottom: '50px' }}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            style={{
              maxWidth: '1200px',
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {categoryArray?.map((item) => {
              return (
                <Grid item xs={6} md={3}>
                  <Card
                    className={
                      item?.title === selectedCategory
                        ? classes.categoryCardActive
                        : classes.categoryCardInActive
                    }
                    onClick={() =>
                      item?.path
                        ? history.push(item?.path)
                        : setSelectedCategory(item?.title)
                    }
                  >
                    <Grid
                      container
                      justifyContent="center"
                      spacing={6}
                      alignItems="center"
                      style={{ flexDirection: 'column' }}
                    >
                      <Grid item>
                        <img
                          src={item?.icon}
                          alt=""
                          style={{ height: '100%', maxHeight: '100px' }}
                        />
                      </Grid>
                      <Grid item>
                        <p style={{ textAlign: 'center' }}>{item?.title}</p>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: '20px',
            maxWidth: '1200px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Card
            style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  placeholder={t('common.search')}
                  fullWidth
                  value={searchMessage}
                  onChange={(e) => setSearchMessage(e?.target?.value)}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: 'rgba(0,0,0,0.6)' }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: '5%',
            backgroundColor: '#F8F8F8',
            padding: '12px 5%',
          }}
        >
          <Grid container>
            {/* <Grid item xs={12}>
                            <p className={classes.messages}>{t("library.messages")}</p>
                        </Grid> */}
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: '5px', marginTop: '5px' }}
              >
                <Grid item>
                  <p className={classes.messages}>{t('library.messages')}</p>
                </Grid>
                {/* <Grid item>
                  <Button
                    onClick={() => history.push(`${path}/message`)}
                    className={classes.exploreMore}
                  >
                    {t('library.exploreMore')}{' '}
                    {i18n.language === 'ar' ? (
                      <ArrowCircleLeftOutlinedIcon
                        className={classes.iconColor}
                      />
                    ) : (
                      <ArrowCircleRightOutlinedIcon
                        className={classes.iconColor}
                      />
                    )}
                  </Button>
                </Grid> */}
              </Grid>
              <Grid
                container
                justifyContent="right"
                style={{ marginBottom: '5px' }}
              >
                <Grid item>
                <Button
                    onClick={() => history.push(`${path}/message`)}
                    className={classes.exploreMore}
                  >
                    {t('library.exploreMore')}{' '}
                    {i18n.language === 'ar' ? (
                      <ArrowCircleLeftOutlinedIcon
                        className={classes.iconColor}
                      />
                    ) : (
                      <ArrowCircleRightOutlinedIcon
                        className={classes.iconColor}
                      />
                    )}
                  </Button>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Carousel
                  isRTL={i18n.language === 'ar' ? true : false}
                  breakPoints={breakPoints}
                  pagination={false}
                  enableAutoPlay={true}
                  autoPlaySpeed={3000}
                  enableSwipe={true}
                  showArrows={false}
                >
                  {messageList
                    ?.filter((item) => item.type === 'message')
                    ?.map((item) => (
                      <MessageCard item={item} />
                    ))}
                </Carousel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '5%', padding: '0 5%' }}>
          <Grid container>
            {/* <Grid item xs={12}>
                            <p className={classes.messages}>{t("library.video")}</p>
                        </Grid> */}
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: '5px' }}
              >
                <Grid item>
                  {/* <p className={classes.messageHeading}>{t("library.videoHeading")}</p> */}
                  <p className={classes.messages}>{t('library.video')}</p>
                </Grid>
                {/* <Grid item>
                  <Button
                    onClick={() => history.push(`${path}/video`)}
                    className={classes.exploreMore}
                  >
                    {t('library.exploreMore')}{' '}
                    {i18n.language === 'ar' ? (
                      <ArrowCircleLeftOutlinedIcon
                        className={classes.iconColor}
                      />
                    ) : (
                      <ArrowCircleRightOutlinedIcon
                        className={classes.iconColor}
                      />
                    )}
                  </Button>
                </Grid> */}
              </Grid>
              <Grid
                container
                justifyContent="right"
                style={{ marginBottom: '5px' }}
              >
                <Grid item>
                  <Button
                    onClick={() => history.push(`${path}/video`)}
                    className={classes.exploreMore}
                  >
                    {t('library.exploreMore')}{' '}
                    {i18n.language === 'ar' ? (
                      <ArrowCircleLeftOutlinedIcon
                        className={classes.iconColor}
                      />
                    ) : (
                      <ArrowCircleRightOutlinedIcon
                        className={classes.iconColor}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Carousel
                  isRTL={i18n.language === 'ar' ? true : false}
                  breakPoints={breakPoints}
                  pagination={false}
                  enableAutoPlay={true}
                  autoPlaySpeed={3000}
                  enableSwipe={true}
                  showArrows={false}
                  focusOnSelect={true}
                  stopOnHover
                >
                  {messageList
                    ?.filter((item) => item.type === 'video')
                    ?.map((item) => (
                      <VideoCard
                        item={item}
                        onPlayFunction={onPlayFunction}
                        selectedPlayId={selectedPlayId}
                      />
                    ))}
                </Carousel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MolemLibrary;
