import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Country01 from 'Assets/aboutUs/Country01.png';
import Country02 from 'Assets/aboutUs/Country02.png';
import Country03 from 'Assets/aboutUs/Country03.png';
import Country04 from 'Assets/aboutUs/Country04.png';
import Country05 from 'Assets/aboutUs/Country05.png';
import Country06 from 'Assets/aboutUs/Country06.png';
import WebsiteLogo1 from 'Assets/aboutUs/Asset1.png';
import WebsiteLogo2 from 'Assets/aboutUs/Asset2.png';
import WebsiteLogo3 from 'Assets/aboutUs/Asset3.png';
import WebsiteLogo4 from 'Assets/aboutUs/Asset4.png';
import WebsiteLogo5 from 'Assets/aboutUs/Asset5.png';
import WebsiteLogo6 from 'Assets/aboutUs/Asset6.png';
import footerLogo from 'Assets/footerLogo.png';
import { color1, color2, color4, color5, color1Hover } from 'Pages/Constant';
import TitleBox from 'Components/TitleBox/TitleBox';
import AccordionFullWidthAPI from 'Components/AccordionFullwidthAPI';
import axios from 'axios';

import AboutBanner from "Assets/Home/carosoel/InnerPageBanners/AboutBanner.png";
import { ABOUTDETAIL } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import HeroBox from 'Components/TitleBox/HeroBox';


const color11 = "rgba(0,0,0,0.1)"

const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: color1,
  },
  h1Label: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "72px"
  },
  h1Text: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "16px"
  },
  contactDetailsHeading: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "10px"
  },
  contactDetailsText: {
    fontSize: "18px",
    textTransform: "none",
    textAlign: "justify",
    marginBottom: "20px"
  },
  countriesMemberHeading: {
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "67px",
    color: color2,
    textAlign: "center"
  },
  countriesMemberText: {
    color: color4,
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "26px",
    textAlign: "center",
    maxWidth: "250px"
  },
  categoryInActive: {
    cursor: "pointer",
    padding: "15px",
    color: color5,
    fontSize: '24px',
    fontWeight: 'bold',
  },
  categoryActive: {
    padding: "15px",
    color: '#fff !important',
    fontSize: '30px',
    backgroundColor: color2,
    fontWeight: 'bold'
  },
  clickableText: {
    cursor: "pointer",
    padding: '10px',
    textAlign: "justify",
    color: '#5EB3E4',
    fontSize: '15px',
    '&:hover': {
      color: color1Hover,
    },
  },
  nonClickableText: {
    padding: '10px',
    fontSize: '15px',
    color: '#5EB3E4',
    lineHeight: '1.5rem',
  },
  card: {
    border: `1px solid ${color5}`,
    borderRadius: '10px'
  },
  tabPanel: {
    padding: '20px'
  },
  TabItem: {
    fontSize: '20px',
    fontWeight: 'normal',
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  websiteLogoMain: {
    paddingRight: "20px",
    borderRight: "1px solid rgba(0,0,0,0.15)",
    [theme.breakpoints.down("md")]: {
      paddingRight: "0px",
      borderRight: "none"
    },
  },
  websiteLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    padding: "10px",
    width: "100%",
    height: '100%',
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    '&:hover': {
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px ,rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
    },
  }
}))

const AboutUs = () => {
  const classes = useStyles()
  const { t } = useTranslation();
  const [aboutArray, setAboutArray] =useState([])
  const [heroBoxDetails, setHeroBoxDetails] = useState()


  // const aboutArray = [
  //   {
  //     title: t('about.aboutArray.title1'),
  //     list: [
  //       {
  //         text: t('about.aboutArray.text1a'),
  //       },
  //     ],
  //   },
  //   {
  //     title: t('about.aboutArray.title2'),
  //     list: [
  //       // {
  //       //   text: t('about.aboutArray.text2a'),
  //       //   path: '/molem-library',
  //       // },
  //       {
  //         text: t('about.aboutArray.text2b'),
  //         path: '/khaliji-investor-week',
  //       },
  //       {
  //         text: t('about.aboutArray.text2c'),
  //         path: '/smart-khaliji-investor-award',
  //       },
  //     ],
  //   },
  //   {
  //     title: t('about.aboutArray.title3'),
  //     type:'sub-list',
  //     list: [
  //       {
  //         subTitle: t('about.aboutArray.text3a'),
  //         type:"list",
  //         list:[
  //           {
  //             text: t('about.aboutArray.text3b'),
  //           },
  //           {
  //             text: t('about.aboutArray.text3c'),
  //           },
  //           {
  //             text: t('about.aboutArray.text3d'),
  //           },
  //         ]
  //       }
  //     ],
  //   },
  // ];

  const aboutPageQuery = useQuery([ABOUTDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      return (
        data,
        setAboutArray(data?.data?.aboutPage?.aboutCardDetails),
        setHeroBoxDetails(data?.data?.aboutPage?.headerDetails)
      )
    }),
  );



  const countriesMemberList = [
    { title: "emirates flag", logo: Country01, path: "https://www.sca.gov.ae/" },
    { title: "bahrain flag", logo: Country02, path: "https://www.cbb.gov.bh/ar/" },
    { title: "saudi flag", logo: Country03, path: "https://cma.org.sa/Pages/default.aspx" },
    { title: "oman flag", logo: Country04, path: "https://cma.gov.om/" },
    { title: "qatar flag", logo: Country05, path: "https://www.qfma.org.qa/Arabic/Pages/default.aspx" },
    { title: "kuwait", logo: Country06, path: "https://www.cma.gov.kw/ar/web/cma" },
  ]
  const websiteLogo = [
    { logo: WebsiteLogo6, href: "https://www.sca.gov.ae/" },
    { logo: WebsiteLogo1, href: "https://www.cbb.gov.bh/ar/" },
    { logo: WebsiteLogo3, href: "https://cma.org.sa/en/Pages/default.aspx" },
    { logo: WebsiteLogo4, href: "https://cma.gov.om/" },
    { logo: WebsiteLogo2, href: "https://www.qfma.org.qa/Arabic/Pages/default.aspx" },
    { logo: WebsiteLogo5, href: "https://www.cma.gov.kw/ar/web/cma" },
  ]
  const headerData = {
    h1Label: t("about.aboutMolem")
  }
  return (
    <div className={classes.contactUs}>
      <HeroBox
        heroBoxDetails={heroBoxDetails}
        textMaxWidth='500px'
      />
      <Box style={{
        backgroundColor: '#F8F8F8',
      }}>
        <Grid
          maxWidth={'xl'}
          container
          margin={'0 auto'}
          alignItems='center'
          justifyContent='center'
          style={{
            padding: '5%',
            marginBottom: '10vh'
          }}
        >
          <Grid item xs={12}>
            {aboutArray && <AccordionFullWidthAPI items={aboutArray} /> }
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        maxWidth={'xl'}
        alignItems="center"
        style={{ margin: "0 auto", paddingLeft: "2.5%" }}
      >
        <Grid item xs={12} md={3} lg={2}>
          <img src={footerLogo} alt="" style={{ width: "100%", maxWidth: "300px", marginLeft: "auto", marginRight: "auto", padding: "30px" }} />
        </Grid>
        <Grid item xs={12} md={9} lg={10} className={classes.websiteLogoMain}>
          <Grid container>
            {websiteLogo?.map((item) => {
              return (
                <Grid item xs={12} sm={6} md={6} lg={4} style={{ padding: "10px" }}>
                  <a className={classes.websiteLogo} style={{ cursor: "pointer" }} target="_black" href={item?.href}>
                    <img src={item?.logo} alt="" style={{ maxHeight: "100px" }} />
                  </a>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      <div style={{ padding: '0 5%', marginBottom: '100px', marginTop: '100px' }}>
      <Grid
        container
        spacing={3}
        style={{maxWidth:"700px", marginLeft:"auto", marginRight:"auto"}}
      >
        {countriesMemberList?.map((item) => {
          return (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              alignItems='center'
              justifyContent='center'
              style={{ display: 'flex', marginBottom: '5%' }}
            >
              {/* <Tooltip title={item?.path} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}> */}
              <a
                target='blank'
                href={item?.path}
                style={{ borderRadius: '50%' }}
              >
                <img
                  src={item.logo}
                  alt=''
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                  }}
                />
              </a>
              {/* </Tooltip> */}
            </Grid>
          );
        })}
      </Grid>
      </div>
      
    </div>
  );
};

export default AboutUs;
