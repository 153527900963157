import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Dialog } from '@mui/material';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'Utils/Yup';
import {
  Btn,
  CheckboxController,
  InputController,
  setProgress,
} from 'Components';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import { Country, State, City } from 'country-state-city';

const CREATE_PAYOUT_BTN = 'CREATE_PAYOUT_BTN';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: '1000px',
  maxHeight: '95vh',
  // overflow:"scroll",
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

const useStyle = makeStyles((theme) => ({
  card: {
    boxShadow: '1px 2px 6px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    margin: '10px',
  },
  titleRight: {
    fontSize: '16px',
    color: 'rgba(14, 109, 167, 1)',
  },
  title: {
    fontSize: '23px',
    color: '#45A041',
    fontWeight: '500',
  },
  btn_add: {
    backgroundColor: 'rgba(62, 199, 230, 1)',
    fontSize: '12px',
    color: 'rgba(255, 255, 255, 1)',
    '&:hover': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: 'rgba(62, 199, 230, 1)',
    },
  },
  btn_more: {
    fontSize: '12px',
    color: 'rgba(113, 205, 226, 1) !important',
    textTransform: 'capitalize',
    '&:hover': {
      color: 'rgba(113, 205, 226, 1)',
    },
  },
  formLabel: {
    fontStyle: 'normal',
    // fontWeight: "bold",
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: '#252F40',
  },
  formSubLabel: {
    fontStyle: 'normal',
    // fontWeight: "bold",
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: '#A0A0A0',
  },
}));

const schema = yup.object().shape({
  FirstName: yup.string().required(),
  email: yup.string().email(),
  policyAccept: yup.string().required('Please accept the terms and policy.'),
  // message: yup.string().required("required"),
});

const FormModal = ({ open, handleClose, handleSuccessModalOpen }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const gender = [
    { title: t('awardForm.male'), value: 'male' },
    { title: t('awardForm.female'), value: 'female' },
  ];
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const handleCloseModal = () => {
    handleClose();
  };

  const form = useForm({
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, errors } = form;

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    console.log(values);
    handleClose();
    handleSuccessModalOpen();
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      fullWidth
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh',
          fontSize: '4.5rem',
        }}
      >
        <h1>{t('award.commingSoon')}!</h1>
      </div>
      {/* <Fade in={open}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <h3 className={classes.title}>{t("awardForm.RegistrationForm")}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseModal}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <form
                        onSubmit={handleSubmit(handleFormSubmission)}
                    >
                        <div style={{ maxHeight: "calc(95vh - 100px)", overflow: "scroll" }}>
                            <Grid container spacing={5} style={{ padding: "20px" }}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.FirstName")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="FirstName"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.FatherName")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="FatherName"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.LastName")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="LastName"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.EmailID")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="EmailID"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.PhoneNo")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="PhoneNo"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.Gender")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="Gender"
                                                select
                                            >
                                                {gender.map((option) => (
                                                    <MenuItem value={option.value}>
                                                        {option.title}
                                                    </MenuItem>
                                                ))}
                                            </InputController>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.Age")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="Age"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.Country")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                size="small"
                                                options={Country.getAllCountries()?.map((option) => option)}
                                                getOptionLabel={(option) => option?.name}
                                                renderInput={(params) => <TextField {...params}
                                                    // value={selectedSubCategory}
                                                    // onChange={(event, newValue) => { setSelectedCountry(event?.target?.value) }}
                                                />}
                                                value={selectedCountry}
                                                onChange={(event, newValue) => { setSelectedCountry(newValue)}}
                                            />
                                           
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.City")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                size="small"
                                                options={City.getCitiesOfCountry(selectedCountry?.isoCode)?.map((option) => option)}
                                                getOptionLabel={(option) => option?.name}
                                                renderInput={(params) => <TextField {...params}
                                                    // value={selectedSubCategory}
                                                    // onChange={(event, newValue) => { setSelectedCountry(event?.target?.value) }}
                                                />}
                                                value={selectedCity}
                                                onChange={(event, newValue) => { setSelectedCity(newValue) }}
                                            />
                                        </Grid>
                                       
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.district")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="district"
                                                multiline={true}
                                                minRows={4}
                                                maxRows={6}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.street")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="street"
                                                multiline={true}
                                                minRows={4}
                                                maxRows={6}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.UploadDocuments")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className={classes.formSubLabel}>{t("awardForm.UploadYourDocuments")}</p>
                                        </Grid>
                                        <Grid item>
                                            <ImageController
                                                name="attachments"
                                                filesLimit={5}
                                                acceptedFiles
                                                form={form}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className={classes.formLabel}>{t("awardForm.VideoLink")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className={classes.formSubLabel}>{t("awardForm.UploadYourVideo")}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                size="small"
                                                control={control}
                                                name="videoLink"
                                                placeholder={t("awardForm.AddVideoLink")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CheckboxController
                                                size="small"
                                                control={control}
                                                name="policyAccept"
                                                label={t("awardForm.AgreePolicy")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN} style={{ textTransform: "none", textAlign: "center" }} fullWidth>
                                        {t("awardForm.Submit")}
                                    </Btn>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Box>
            </Fade> */}
    </Dialog>
  );
};

export default FormModal;
