import { Button, Card, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import RewardMoney from './Components/RewardMoney';
import FormModal from './Components/FormModal';
import AwardCommonModal from './Components/AwardCommonModal';
import FormSubmitModal from './Components/FormSubmitModal';
import TitleBox from 'Components/TitleBox/TitleBox';
import FlipcardHex from './Components/FlipCardHex/FlipcardHex';
import { color2 } from 'Pages/Constant';

import FAQPolicyCard from 'Assets/awards/FAQPolicyCard.svg';

import VideoIconColored from 'Assets/awards/VideoIconColored.png';
import PhotoIconColored from 'Assets/awards/PhotoIconColored.png';
import PencilIconColored from 'Assets/awards/PencilIconColored.png';
import AwardIconColored from 'Assets/awards/AwardIconColored.png';

import AwardBanner from 'Assets/Home/carosoel/InnerPageBanners/AwardBanner.png';

// import Accordion from 'Components/Accordion';
import AccordionApi from 'Components/AccordionApi';


import awardCategory1 from 'Assets/awards/awardCategory1.svg';
import awardCategory2 from 'Assets/awards/awardCategory2.svg';
import awardCategory3 from 'Assets/awards/awardCategory3.svg';
import awardCategory4 from 'Assets/awards/awardCategory4.svg';
import awardCategory5 from 'Assets/awards/awardCategory5.svg';
import awardCategory6 from 'Assets/awards/awardCategory6.svg';
import awardCategory7 from 'Assets/awards/awardCategory7.svg';
import awardCategory8 from 'Assets/awards/awardCategory8.svg';
import awardCategory9 from 'Assets/awards/awardCategory9.svg';
import awardCategory10 from 'Assets/awards/awardCategory10.svg';
import awardCategory11 from 'Assets/awards/awardCategory11.svg';
import awardCategory12 from 'Assets/awards/awardCategory12.svg';
import awardCategory13 from 'Assets/awards/awardCategory13.svg';
import HelmetWrapper from 'Components/HelmetWrapper/HelmetWrapper';
import { useQuery } from 'react-query';
import { AWARDDETAIL } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import SafeHTML from 'Components/SafeHTML/SafeHTML';
import HeroBox from 'Components/TitleBox/HeroBox';



const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: 'rgba(0,0,0,1)',
  },
  h1Label: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '72px',
  },
  h1Text: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '16px',
  },
  commingSoon: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '70px',
    lineHeight: '58px',
    marginBottom: '10px',
  },
  text1: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    marginBottom: '50px',
  },
  h3text: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '26px',
    color: 'black',
    marginBottom: '24px',
    textAlign: 'center',
  },
  ptext: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '37px',
    color: '#5D6180',
  },
  cardText: {
    fontStyle: 'normal',
    fontSize: '18px',
    color: 'rgba(0,0,0,0.4)!important',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
      color: '#5D6180 !important',
    },
  },
  iconColor: {
    height: '25px',
  },
  categoryCardInActive: {
    borderRadius: '20px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    '&:hover': {
      boxShadow:
        'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
    },
  },
  FAQPolicyCard: {
    minHeight: '300px',
    maxWidth: '1000px',
    background: 'rgba(0,0,0,0.05)',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5vh',
    marginBottom: '10vh',
    borderRadius: '30px',
    backgroundImage: `url(${FAQPolicyCard})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  helpText: {
    fontStyle: 'normal',
    fontSize: '18px',
    color: 'black',
    marginBottom: '10px',
    textAlign: 'center',
  },
  btn_policyFAQ: {
    color: 'black',
    padding: '10px 100px',
    backgroundColor: 'white',
    borderRadius: '5px',
    '&:hover': {
      // backgroundColor: "rgba(61, 141, 187,0.1)",
      backgroundColor: color2,
      color: 'white',
    },
  },
  hoverAbleImage: {
    '& img': {
      filter: 'grayscale(100%)',
    },
    '&:hover': {
      '& img': { filter: 'grayscale(0%)' },
    },
  },
  awardSteps: {
    padding: '50px 50px 0',
  },
  awardSatgesLine: {
    height: "2px",
    backgroundColor: "#5D6180",
    position: "absolute",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    zIndex: "-1",
    top: "22%",
    left: "11%",
    width: " 78%",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  awardSteps__item: {
    maxWidth: '200px',
  },
  awardSteps__Number: {
    width: '20px',
    height: '20px',
    backgroundColor: '#9fcf9c',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '12px auto',
  },
  awardSteps__Text: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#5D6180',
  },
  suggestedItemBox: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    padding: '20px',
    [theme.breakpoints.up("sm")]: {
      width: '25%',
    },
    [theme.breakpoints.up("md")]: {
      width: '20%',
    },
    "&:hover": {
      color: '#9fcf9c',
      cursor: 'select',
      transition: "color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    '& img': {
      marginBottom: '20px',
      filter: "grayscale(100%)",
      transition: "filter 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    '&:hover img': {
      filter: "grayscale(0%)",
    }
  }
}));

const ActiveModalContent = ({ activeItem }) => {
  return (
    <div style={{ marginRight: "20px" }}>
      <SafeHTML
        variant='body1'
        component={'div'}>
        {activeItem.listText}
      </SafeHTML>
    </div>
    // <ul style={{
    //   listStyle: "disc",
    //   marginRight: "20px"
    // }}>
    //   {activeItem.listText.map((item, index) => {
    //     return (
    //       <li
    //         key={index}
    //         style={{
    //           marginBottom: '10px',
    //           color: '#5D6180',
    //         }}
    //       >
    //         {item}
    //       </li>
    //     );
    //   })}
    // </ul>
  );
}

const AwardPage = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [openPolciyModal, setOpenPolciyModal] = useState(false);
  const [openCriteriaModal, setOpenCriteriaModal] = useState(false);
  const [activePolicy, setActivePolicy] = useState();
  const [activeCriteria, setActiveCriteria] = useState();
  const [open, setOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [policyCriteriaPhotography, setPolicyCriteriaPhotography] = useState()
  const [policyCriteriaVideography, setPolicyCriteriaVideography] = useState()
  const [policyCriteriaDrawing, setPolicyCriteriaDrawing] = useState()
  const [termsConditionss, setTermsConditionss] = useState()
  const [heroBoxDetails, setHeroBoxDetails] = useState()

  const awardPageQuery = useQuery([AWARDDETAIL], () =>
    http.get(`/api/v1/pages`, {}).then(({ data }) => {
      return (
        setPolicyCriteriaPhotography(data?.data?.gulfSmartInvestorAward?.photography),
        setPolicyCriteriaVideography(data?.data?.gulfSmartInvestorAward?.videography),
        setPolicyCriteriaDrawing(data?.data?.gulfSmartInvestorAward?.drawing),
        setTermsConditionss(data?.data?.gulfSmartInvestorAward?.accordianDetails),
        setHeroBoxDetails(data?.data?.gulfSmartInvestorAward?.headerDetails),
        data
      )
    }),
  );

  const handleSuccessModalOpen = () => {
    setSuccessModalOpen(true);
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePolicyModal = () => {
    setOpenPolciyModal(false);
  };

  const policyModalHandler = (item) => {
    setOpenPolciyModal(true);
    setActivePolicy(item);
  };

  const criteriaModalHandler = (item) => {
    setActiveCriteria(item);
    setOpenCriteriaModal(true);
  };

  const handleCloseCriteriaModal = () => {
    setOpenCriteriaModal(false);
  };

  const awardsCategoryArray = [
    {
      title: t('award.videography'),
      icon: {
        src: VideoIconColored,
      },
      path: '/smart-khaliji-investor-award/videography',
      policy: policyCriteriaVideography?.policy,
      criteria: policyCriteriaVideography?.criteria,
      // policy: {
      //   title: t('award.videoTrackTerms'),
      //   listText: [
      //     t('award.videoTrackTermsText1'),
      //     t('award.videoTrackTermsText2'),
      //     t('award.videoTrackTermsText3'),
      //     t('award.videoTrackTermsText4'),
      //     t('award.videoTrackTermsText5'),
      //     t('award.videoTrackTermsText6'),
      //   ],
      // },
      // criteria: {
      //   title: t('award.videoTrackCriteria'),
      //   listText: [
      //     t('award.videoTrackCriteriaText1'),
      //     t('award.videoTrackCriteriaText2'),
      //     t('award.videoTrackCriteriaText3'),
      //     t('award.videoTrackCriteriaText4'),
      //     t('award.videoTrackCriteriaText5'),
      //   ],
      // },
    },
    {
      title: t('award.photographer'),
      icon: {
        src: PhotoIconColored,
      },
      path: '/smart-khaliji-investor-award/photography',
      policy: policyCriteriaPhotography?.policy,
      criteria: policyCriteriaPhotography?.criteria,
      // policy: {
      //   title: t('award.photoTerms'),
      //   listText: [
      //     t('award.photoTermsText1'),
      //     t('award.photoTermsText2'),
      //     t('award.photoTermsText3'),
      //     t('award.photoTermsText4'),
      //     t('award.photoTermsText5'),
      //     t('award.photoTermsText6'),
      //     t('award.photoTermsText7'),
      //   ],
      // },
      // criteria: {
      //   title: t('award.photoCriteria'),
      //   listText: [
      //     t('award.photoCriteriaText1'),
      //     t('award.photoCriteriaText2'),
      //     t('award.photoCriteriaText3'),
      //   ],
      // },
    },
    {
      title: t('award.drawing'),
      icon: {
        src: PencilIconColored,
      },
      path: '/smart-khaliji-investor-award/drawing',
      policy: policyCriteriaDrawing?.policy,
      criteria: policyCriteriaDrawing?.criteria,
      // policy: {
      //   title: t('award.drawingTerms'),
      //   listText: [
      //     t('award.drawingTermsText1'),
      //     t('award.drawingTermsText2'),
      //     t('award.drawingTermsText3'),
      //     t('award.drawingTermsText4'),
      //     t('award.drawingTermsText5'),
      //     t('award.drawingTermsText6'),
      //   ],
      // },
      // criteria: {
      //   title: t('award.drawingCriteria'),
      //   listText: [
      //     t('award.drawingCriteriaText1'),
      //     t('award.drawingCriteriaText2'),
      //     t('award.drawingCriteriaText3'),
      //     t('award.drawingCriteriaText4'),
      //   ],
      // },
    },
  ];

  const awardStages = {
    title: t('awardStages.title'),
    list: [
      {
        title: t('awardStages.text1'),
        text: t('awardStages.subText1'),
      },
      {
        title: t('awardStages.text2'),
        text: t('awardStages.subText2'),
      },
      {
        title: t('awardStages.text3'),
        text: t('awardStages.subText3'),
      },
      {
        title: t('awardStages.text4'),
        text: t('awardStages.subText4'),
      },
      {
        title: t('awardStages.text5'),
        text: t('awardStages.subText5'),
      },
    ],
  };

  const suggestedTopics = {
    title: t('suggestedTopics.title'),
    paragraph: t('suggestedTopics.paragraph'),
    list: [
      {
        title: t('suggestedTopics.text1'),
        icon: awardCategory5
      },
      {
        title: t('suggestedTopics.text2'),
        icon: awardCategory4
      },
      {
        title: t('suggestedTopics.text3'),
        icon: awardCategory3
      },
      {
        title: t('suggestedTopics.text4'),
        icon: awardCategory2
      },
      {
        title: t('suggestedTopics.text5'),
        icon: awardCategory1
      },
      {
        title: t('suggestedTopics.text6'),
        icon: awardCategory10
      },
      {
        title: t('suggestedTopics.text7'),
        icon: awardCategory9
      },
      {
        title: t('suggestedTopics.text8'),
        icon: awardCategory8
      },
      {
        title: t('suggestedTopics.text9'),
        icon: awardCategory7
      },
      {
        title: t('suggestedTopics.text10'),
        icon: awardCategory6
      },
      {
        title: t('suggestedTopics.text11'),
        icon: awardCategory13
      },
      {
        title: t('suggestedTopics.text12'),
        icon: awardCategory12
      },
      {
        title: t('suggestedTopics.text13'),
        icon: awardCategory11
      },
    ],
  };

  const termsConditions = [
    {
      title: t('termsConditions.title'),
      type: 'list',
      // generate array using locale file array length
      list: Array.from({ length: +(t(`termsConditions.length`)) }, (v, k) => ({
        text: t('termsConditions.text' + (k + 1))
      })),
    },
    {
      title: t('intellectualRights.title'),
      type: 'list',
      list: [
        {
          text: t('intellectualRights.text1'),
        },
        {
          text: t('intellectualRights.text2'),
        },
        {
          text: t('intellectualRights.text3'),
        }
      ],
    },
    {
      title: t('award.commonQuestions'),
      type: 'q-a',
      // generate array using locale file array length
      list: [
        { question: t('award.Q1'), answer: t('award.A1'), },
        { question: t('award.Q2'), answer: t('award.A2'), },
        { question: t('award.Q3'), answer: t('award.A3'), },
        { question: t('award.Q4'), answer: t('award.A4'), },
        { question: t('award.Q5'), answer: t('award.A5'), },
        { question: t('award.Q6'), answer: t('award.A6'), },
        { question: t('award.Q7'), answer: t('award.A7'), },
        { question: t('award.Q8'), answer: t('award.A8'), },
        { question: t('award.Q9'), answer: t('award.A9'), },
        {
          question: t('award.Q10'), answer: <span>{t('award.A10a')} &nbsp;<span style={{ cursor: 'pointer', textDecoration: 'underline', }} onClick={() => history.push("/contact")}>{t('award.A10b')}</span></span>
        },
      ]
    },
    {
      title: t('award.evacuationResponsibilaty'),
      type: 'list',
      list: [
        {
          text: t('award.evacuationResponsibilatyText1'),
        }
      ]
    }
  ];

  const detailsData1 = [
    {
      title: t('award.title1'),
      icon: {
        src: AwardIconColored,
      },
      // text: t('award.text1'),
      text: awardPageQuery?.data?.data?.gulfSmartInvestorAward?.aboutAward,
    },
    {
      title: t('award.title2'),
      icon: {
        src: AwardIconColored,
      },
      // text: t('award.text2'),
      text: awardPageQuery?.data?.data?.gulfSmartInvestorAward?.targetedAudience,
    },
  ];
  var headerData = {
    h1Label: t('award.khalijiInvestorAward'),
    // text1: t("investorWeek.text1")
  };

  return (
    <div className={classes.contactUs}>
      <HeroBox
        heroBoxDetails={heroBoxDetails}
        textMaxWidth='500px'
      />
      <Grid container spacing={0} justifyContent='center'>
        {detailsData1?.map((item) => {
          return (
            <Grid item xs={12} md={5} style={{ maxWidth: 320 }}>
              <FlipcardHex data={item} />
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5% 0',
        }}
        margin={'0 auto'}
        spacing={2}
      >
        <h3 className={classes.h3text} style={{ textAlign: 'center' }}>
          {awardStages.title}
        </h3>
        <Grid display={'flex'} xs={12} className={classes.awardSteps}>
          <Grid
            container
            columnSpacing={{ xs: 3, sm: 8, md: 10 }}
            alignItems='center'
            justifyContent={'center'}
            style={{
              position: 'relative',
              width: 'auto',
              margin: '0 auto',
            }}
          >
            <Box
              height={'1px'}
              width={'100%'}
              bgcolor='ActiveBorder'
              className={classes.awardSatgesLine}
            ></Box>
            {awardStages.list.map((item, id) => (
              <Grid
                display={'flex'}
                flexDirection={'column'}
                textAlign={'center'}
                alignItems={'center'}
                minWidth={'200px'}
                xs='12'
                md='auto'
                key={id}
                item
                className={classes.awardSteps__Item}
              >
                <span className={classes.awardSteps__Number}></span>
                <div className={classes.awardSteps__Text}>
                  <Typography variant='h6' component='h6'>
                    {item.title}
                  </Typography>
                  <Typography variant='body2' component='div'>
                    {item.text}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        style={{
          padding: '0 5%',
          paddingBottom: '7vh',
          paddingTop: '7vh',
          background: '#F8F8F8',
        }}
      >
        <Grid item xs={12}>
          <h3 className={classes.h3text}>{t('award.title3')}</h3>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: '24px 0',
          }}
        >
          <Grid container spacing={5} justifyContent='center'>
            {awardsCategoryArray?.map((item) => {
              return (
                <Grid item xs={12} md={4} style={{ maxWidth: '360px' }}>
                  <Card
                    className={[
                      classes.categoryCardInActive,
                      classes.hoverAbleImage,
                    ].join(' ')}
                    onMouseEnter={() => null}
                  >
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        flexDirection: 'column',
                        marginBottom: '15px',
                        // cursor: 'pointer',
                        padding: '10px 10px 0 10px',
                      }}
                    // onClick={() => history.push(item.path)}
                    >
                      <Grid
                        item
                        style={{
                          marginBottom: '20px',
                          background: '#F8FAFB',
                          borderRadius: 6,
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '210px',
                        }}
                      >
                        <img
                          src={item?.icon?.src}
                          alt=''
                          style={{ height: '100%', maxHeight: '100px' }}
                        />
                      </Grid>
                      <Grid item style={{ width: '100%' }}>
                        <p>{item?.title}</p>
                      </Grid>
                    </Grid>
                    <Grid container style={{ padding: '0px 20px 20px 20px' }}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent='space-between'
                          className={classes.cardText}
                          onClick={() => setOpen(true)}
                        >
                          <Grid item>
                            <h3>{t('award.registerNow')}</h3>
                          </Grid>
                          <Grid item>
                            {i18n.language === 'ar' ? (
                              <ArrowCircleLeftOutlinedIcon
                                className={classes.iconColor}
                              />
                            ) : (
                              <ArrowCircleRightOutlinedIcon
                                className={classes.iconColor}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent='space-between'
                          className={classes.cardText}
                          onClick={() => policyModalHandler(item?.policy)}
                        >
                          <Grid item>
                            <h3>{t('award.policy')}</h3>
                          </Grid>
                          <Grid item>
                            {i18n.language === 'ar' ? (
                              <ArrowCircleLeftOutlinedIcon
                                className={classes.iconColor}
                              />
                            ) : (
                              <ArrowCircleRightOutlinedIcon
                                className={classes.iconColor}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent='space-between'
                          className={classes.cardText}
                          onClick={() => criteriaModalHandler(item?.criteria)}
                        >
                          <Grid item>
                            <h3>{t('award.judgingCriteria')}</h3>
                          </Grid>
                          <Grid item>
                            {i18n.language === 'ar' ? (
                              <ArrowCircleLeftOutlinedIcon
                                className={classes.iconColor}
                              />
                            ) : (
                              <ArrowCircleRightOutlinedIcon
                                className={classes.iconColor}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: '7vh 0',
        }}
        margin={'0 auto'}
        spacing={2}
        maxWidth='xl'
      >
        <h3 className={classes.h3text} style={{ textAlign: 'center' }}>
          {suggestedTopics.title}
        </h3>
        <Grid
          container
          alignItems={'center'}
          justifyContent='center'
          paddingBottom={'40px'}
        >
          <Typography variant='body1' component='div'>
            {suggestedTopics.paragraph}
          </Typography>
        </Grid>
        <Grid display={'flex'} xs={12} className={classes.awardSteps}>
          <Grid
            container
            alignItems={'center'}
            justifyContent='center'
            rowSpacing={10}
            columnSpacing={{ xs: 8, sm: 8, md: 10 }}
            paddingBottom={10}
          >
            {suggestedTopics.list.map((item, id) => (
              <Grid key={id} className={classes.suggestedItemBox} item>
                <img src={item.icon} />
                <Typography
                  whiteSpace={'nowrap'}
                  className={classes.suggestedTopics__title}
                  variant='body2'
                  component='h6'
                >
                  {item.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        maxWidth={'lg'}
        margin={'0 auto'}
        style={{
          padding: '3vh 0',
        }}
        alignItems='center'
        justifyContent='center'
      >
        <RewardMoney />
        <Grid
          item
          xs={12}
          style={{
            marginTop: '-50px',
            transform: 'translateY(-50)',
          }}
        >
          {/* <Accordion items={termsConditions} /> */}
          {termsConditionss && <AccordionApi items={termsConditionss} />}

        </Grid>
      </Grid>

      <Grid container justifyContent='center' alignItems='center' style={{
        padding: '50px 0',
      }}>
      </Grid>

      {/* <Grid
        container
        justifyContent='center'
        alignItems='center'
        className={classes.FAQPolicyCard}
      >
        <Grid item>
          <Grid conainer justifyContent='center'>
            <Grid item xs={12}>
              <h3
                className={classes.helpText}
                style={{ cursor: 'pointer', marginBottom: '20px' }}
                onClick={() => history.push('/contact')}
              >
                {t('award.help')}
              </h3>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.btn_policyFAQ}
                className={classes.helpText}
                onClick={() => history.push('/FAQs')}
                onClick={() => FaqModalHandler()}
              >
                {t('award.FAQ&Policy')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <AwardCommonModal
        open={openCriteriaModal}
        handleClose={handleCloseCriteriaModal}
        handleSuccessModalOpen={handleSuccessModalOpen}
        title={t('award.judgingCriteria')}
      >
        {activeCriteria && <ActiveModalContent activeItem={activeCriteria} />}
      </AwardCommonModal>
      <AwardCommonModal
        open={openPolciyModal}
        handleClose={handleClosePolicyModal}
        handleSuccessModalOpen={handleSuccessModalOpen}
        title={activePolicy?.title}
        maxWidth='sm'
      >
        {activePolicy && <ActiveModalContent activeItem={activePolicy} />}
      </AwardCommonModal>
      <FormModal
        open={open}
        handleClose={handleClose}
        handleSuccessModalOpen={handleSuccessModalOpen}
      />
      <FormSubmitModal
        open={successModalOpen}
        handleClose={handleSuccessModalClose}
      />
    </div>
  );
};

export default AwardPage;
