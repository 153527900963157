import { Button, Grid, Card } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Flipcard from './FlipCard/Flipcard';
import styles1 from './FlipCard/Flipcard.module.css';
import { color2 } from 'Pages/Constant';

const useStyles = makeStyles((theme) => ({
  newRelease: {
    // height: "700px",
  },
  btn_primary: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '28px',
    lineHeight: '180%',
    textAlign: 'center',
    color: '#99A7AC',
    borderRadius: '0px',
    borderColor: '#B4D8F1',
    padding: '0px 28px',
    border: '0px solid rgb(180, 216, 241)',
    borderBottomWidth: '3px',
    '&:hover': {
      background: 'rgba(0,0,0,0.05)',
    },
  },

  btn_primary_selected: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '28px',
    lineHeight: '180%',
    padding: '0px 28px',
    textAlign: 'center',
    color: '#042a30',
    background: '#f9f8f9',
    borderRadius: '0px',
    border: '0px solid #5eb3e4',
    borderBottomWidth: '3px',
    '&:hover': {
      background: '#f9f8f9',
    },
  },
}));

const RewardMoney = () => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const [newReleaseSelection, setNewReleaseSelection] = useState('1');

  const videographyArray = [
    { position: t('award.firstAward'), rewardAmount: '50,000' },
    { position: t('award.secondAward'), rewardAmount: '40,000' },
    { position: t('award.thirdAward'), rewardAmount: '30,000' },
  ];

  const photographyArray = [
    { position: t('award.firstAward'), rewardAmount: '40,000' },
    { position: t('award.secondAward'), rewardAmount: '30,000' },
    { position: t('award.thirdAward'), rewardAmount: '20,000' },
  ];

  const drawingArray = [
    { position: t('award.firstAward'), rewardAmount: '30,000' },
    { position: t('award.secondAward'), rewardAmount: '20,000' },
    { position: t('award.thirdAward'), rewardAmount: '15,000' },
  ];

  const rewardArrayTitleList = [
    {
      title: `${t('award.videography')}`,
    },
    {
      title: `${t('award.photographer')}`,
    },
    {
      title: `${t('award.drawing')}`,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <h1
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '42px',
                lineHeight: '24px',
                color: 'black',
                marginTop: '0',
                marginBottom: '24px',
                textAlign: 'center',
              }}
            >
              {t('award.award')}
            </h1>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '50px' }}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={newReleaseSelection}>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid
                container
                justifyContent="center"
                // style={{ marginBottom: '80px' }}
              >
                <Grid item>
                  <Grid
                    container
                    justifyContent="center"
                    // style={{
                    //   border: '0px solid rgb(180, 216, 241)',
                    //   borderBottomWidth: '3px',
                    // }}
                  >
                    {rewardArrayTitleList?.map((item, index) => {
                      return (
                        <Grid item>
                          <Button
                            value="1"
                            className={
                              newReleaseSelection === `${index + 1}`
                                ? styles.btn_primary_selected
                                : styles.btn_primary
                            }
                            onMouseEnter={() =>
                              setNewReleaseSelection(`${index + 1}`)
                            }
                          >
                            {item?.title}
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <TabPanel value="1">
              <Grid
                container
                spacing={1}
                // style={{
                //   maxWidth: '1000px',
                //   marginLeft: 'auto',
                //   marginRight: 'auto',
                // }}
              >
                {videographyArray?.map((item, i) => (
                  <Grid item xs={12} md={4}>
                    <div
                      className={styles1.wrapper_animation_grid}
                      style={{ zIndex: i === 0 ? 3 : i === 2 ? 1 : 2, "--overlayColor": "#0c353f" }}
                    >
                      <div className={styles1.overlayDark}></div>
                      <div>
                        <p className={styles1.rewardAmountGrid}>
                          {item.position}
                        </p>
                      </div>
                      <div>
                        <p className={styles1.rewardPrize}>
                          {item.rewardAmount} <br />
                          {t('award.SAR')}
                        </p>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid
                container
                spacing={1}
                // style={{
                //   maxWidth: '1000px',
                //   marginLeft: 'auto',
                //   marginRight: 'auto',
                // }}
              >
                {photographyArray?.map((item, i) => (
                  <Grid item xs={12} md={4}>
                    <div
                      className={styles1.wrapper_animation_grid}
                      style={{ zIndex: i === 0 ? 3 : i === 2 ? 1 : 2, "--overlayColor": "#365a61" }}
                    >
                      <div className={styles1.overlayMedium}></div>
                      <div>
                        <p className={styles1.rewardAmountGrid}>
                          {item.position}
                        </p>
                      </div>
                      <div>
                        <p className={styles1.rewardPrize}>
                          {item.rewardAmount} <br />
                          {t('award.SAR')}
                        </p>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid
                container
                spacing={1}
                // style={{
                //   maxWidth: '1000px',
                //   marginLeft: 'auto',
                //   marginRight: 'auto',
                // }}
              >
                {drawingArray?.map((item, i) => (
                  <Grid item xs={12} md={4}>
                    <div
                      className={styles1.wrapper_animation_grid}
                      style={{ zIndex: i === 0 ? 3 : i === 2 ? 1 : 2, "--overlayColor": "#6f848b" }}
                    >
                      <div className={styles1.overlayLight}></div>
                      <div>
                        <p className={styles1.rewardAmountGrid}>
                          {item.position}
                        </p>
                      </div>
                      <div>
                        <p className={styles1.rewardPrize}>
                          {item.rewardAmount} <br />
                          {t('award.SAR')}
                        </p>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RewardMoney;
